import {
  type FC,
  type HTMLProps,
  type JSX,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Loader } from '@/components/ui/Loader';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { type ProgressDiagramPath } from '@/components/platform/common/ProgressCircle/ProgressBar.typedefs';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/constants';
import styles from './ProgressCircle.module.scss';

interface Props {
  progress: number;
  size?: number | null;
  withCaption?: boolean;
  strokeWidth?: number;
  pathColor?: string;
  trailColor?: string;
  unit?: string;
  maxValue?: number;
  rotation?: number;
  pieChart?: boolean;
  withLoader?: boolean;
  caption?: string | JSX.Element;
  textClassName?: string;
  diagramPaths?: ProgressDiagramPath[];
  pathTransitionDuration?: number;
  dataQa?: string;
}

type ProgressCircleProps = HTMLProps<HTMLDivElement>;

export const ProgressCircleWrapper: FC<ProgressCircleProps> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <div className={cn(className, styles.circleWrapper)} {...rest}>
      {children}
    </div>
  );
};

export const ProgressCircle: FC<Props> = ({
  progress,
  size = 48,
  withCaption = true,
  strokeWidth,
  pathColor = '#38C976',
  trailColor = '#EBF9F1',
  unit = '%',
  maxValue = 100,
  rotation = 0.75,
  pieChart = false,
  withLoader = true,
  caption = '',
  textClassName = typography.platformH1,
  diagramPaths = EMPTY_ARRAY,
  pathTransitionDuration = 0.7,
  dataQa,
}) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const style = useMemo(() => (
    size
      ? {
        '--size': size,
        width: `${size}px`,
        height: `${size}px`,
      }
      : undefined
  ), [size]);

  const text = caption || `${progress}${unit}`;

  const computedStrokeWidth = useMemo(() => {
    if (strokeWidth) {
      return strokeWidth;
    }

    if (size) {
      return size / 6;
    }

    return undefined;
  }, [size, strokeWidth]);

  const computedLoaderSize = useMemo(() => {
    if (size) {
      return size / 2.5;
    }

    return 20;
  }, [size]);

  const diagramLayers: JSX.Element | null = useMemo(
    () => (
      diagramPaths
        .reduce((acc: JSX.Element | null, currentPath) => {
          const {
            color: diagramPathColor,
            value: diagramPathValue,
            id,
          } = currentPath;

          return (
            <CircularProgressbarWithChildren
              key={id}
              value={ready
                ? diagramPathValue
                : 0}
              strokeWidth={computedStrokeWidth}
              styles={buildStyles({
                rotation,
                pathColor: diagramPathColor,
                pathTransitionDuration: 0.7,
                ...(pieChart
                  ? { strokeLinecap: 'butt' }
                  : EMPTY_OBJECT),
              })}
            >
              {acc}
            </CircularProgressbarWithChildren>
          );
        }, null)),
    [computedStrokeWidth, diagramPaths, pieChart, ready, rotation],
  );

  const value = ready
    ? progress
    : 0;

  return (
    <ProgressCircleWrapper
      style={style}
      data-qa={dataQa}
      data-qa-progress={
        dataQa
          ? `${dataQa}-${value}`
          : value
      }
    >
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={computedStrokeWidth}
        styles={buildStyles({
          rotation,
          trailColor,
          pathColor,
          pathTransitionDuration,
          ...(pieChart
            ? { strokeLinecap: 'butt' }
            : EMPTY_OBJECT),
        })}
        maxValue={maxValue}
      >
        {diagramLayers || (
          <>
            {(!!caption || withCaption) && (
              <p className={textClassName}>
                {text}
              </p>
            )}
            {withLoader && (
              <Loader
                size={computedLoaderSize}
                loading={!ready}
                className={styles.loader}
              />
            )}
          </>
        )}
      </CircularProgressbarWithChildren>
    </ProgressCircleWrapper>
  );
};
